<template>
  <div>
    <div class="banner">
      <div class="title">Tensor Pro</div>
    </div>

    <div class="productInfo">
      <div class="title">{{ $t('产品简介') }}</div>
      <div :class="$i18n.locale == 'en' ? 'en_info' : 'zh_info'">
        {{
          $t(
            'Tensor Pro是一款能用于室内外全场景的高精度3D相机，可应用于精确测量等场景。搭载自研3D成像算法，室外场景下4米距离内可以达到毫米级深度误差，复杂光照条件下有优良的成像效果。RGB与深度图有4K超高分辨率，RGB图画质清晰，可为后续图像识别算法提供高质量的输入。内置GPU算力，配合SIGAI的3D视觉库及相关开发工具，可快速实现3D视觉算法并集成到相机内部。'
          )
        }}
      </div>
    </div>

    <div class="productNumber">
      <div class="title">{{ $t('产品参数') }}</div>
      <div class="img" v-show="$i18n.locale == 'zh'">
        <img src="../../../assets/TensorPro/数据.png" alt="" />
      </div>
      <div class="img" v-show="$i18n.locale == 'en'">
        <img src="../../../assets/TensorPro/数据en.png" alt="" />
      </div>
    </div>

    <div class="wood">
      <div class="title">{{ $t('成像效果-木材与工程机械') }}</div>
      <div class="img">
        <img src="../../../assets/TensorPro/木材.png" alt="" />
      </div>
    </div>

    <div class="photovoltaicPanel">
      <div class="title">{{ $t('成像效果-光伏板') }}</div>
      <div class="img">
        <img src="../../../assets/TensorPro/光伏板.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.banner {
  height: 480px;
  background: url(../../../assets/TensorPro/首页.png) no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  .title {
    color: #344f98;
    font-size: 72px;
    font-weight: 700;
    line-height: 104.26px;
  }
}
.productInfo {
  padding-top: 51px;
  height: 280px;
  background: #f5f6fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    color: #344f98;
    line-height: 43.44px;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 22px;
  }
  .zh_info {
    color: #808080;
    width: 1119px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.96px;
  }
  .en_info {
    color: #808080;
    width: 1119px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26.06px;
  }
}

.productNumber {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 48px;
    margin-bottom: 29px;
    font-size: 30px;
    font-weight: 500;
    color: #344f98;
    line-height: 43.44px;
  }
  .img {
    width: 1119px;
    height: 522px;
    margin-bottom: 65px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.wood {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f6fa;
  .title {
    margin-top: 37px;
    margin-bottom: 29px;
    font-size: 30px;
    font-weight: 500;
    color: #344f98;
    line-height: 43.44px;
  }
  .img {
    width: 983px;
    height: 319px;
    margin-bottom: 75px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.photovoltaicPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  .title {
    margin-top: 37px;
    margin-bottom: 29px;
    font-size: 30px;
    font-weight: 500;
    color: #344f98;
    line-height: 43.44px;
  }
  .img {
    width: 983px;
    height: 319px;
    margin-bottom: 75px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
